
<!-- ======= Header ======= -->
<app-header [active]="4"></app-header>

<main id="main">

  <!-- ======= Breadcrumbs ======= -->
  <section class="breadcrumbs">
    <div class="container">
      <h2>{{ 'header.portfolio' | translate }}</h2>

    </div>
  </section><!-- End Breadcrumbs -->

  <section class="inner-page">
    <div class="container" data-aos="fade-up">
      <!-- ======= Our Portfolio Section ======= -->
      <section id="portfolio" class="portfolio section-bg">
        <div class="container" data-aos="fade-up" data-aos-delay="100">
          <div class="section-title">
            <h2>
              {{ 'portfolio.title' | translate }}
            </h2>
            <p>
              {{ 'portfolio.content' | translate }}
            </p>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <ul id="portfolio-flters">
                <li data-filter="*" class="filter-active">
                  {{ 'portfolio.item1' | translate }}
                </li>
                <li data-filter=".filter-app">
                  {{ 'portfolio.item2' | translate }}

                </li>
                <li data-filter=".filter-card">
                  {{ 'portfolio.item3' | translate }}

                </li>
                <li data-filter=".filter-web">
                  {{ 'portfolio.item4' | translate }}

                </li>
              </ul>
            </div>
          </div>
          <div class="row portfolio-container">
            <div class="col-lg-4 col-md-6 portfolio-item filter-app">
              <div class="portfolio-wrap">
                <img src="/assets/img/portfolio/portfolio-11.gif" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>{{ 'portfolio.item2' | translate }}</h4>
                  <p>
                    {{ 'portfolio.item5' | translate }}
                    </p>
                  <div class="portfolio-links">
                    <a routerLink="/portfolio/1" title="More Details" ><i class="icofont-eye"></i></a>

                    <a href="http://tootreeweb.com/" title="view to web side" target="_blank" ><i class="icofont-external-link"></i></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 portfolio-item filter-app">
              <div class="portfolio-wrap">
                <img src="/assets/img/portfolio/portfolio-44.gif" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>{{ 'portfolio.item2' | translate }}</h4>
                  <p>
                    {{ 'portfolio.item6' | translate }}
                    </p>
                  <div class="portfolio-links">
                    <!--<a routerLink="/portfolio/2" title="More Details"><i class="icofont-eye"></i></a>-->
                    <a href="http://delenscio.be/" title="More Details" target="_blank" ><i class="icofont-external-link"></i></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-wrap">
                <img src="/assets/img/portfolio/portfolio-77.gif" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>{{ 'portfolio.item2' | translate }}</h4>
                  <p>
                    {{ 'portfolio.item7' | translate }}
                     </p>
                  <div class="portfolio-links">
                    <!-- <a routerLink="/portfolio/3" title="More Details"><i class="icofont-eye"></i></a>-->
                     <a href="http://bpsdatacollection.azurewebsites.net/" title="More Details" target="_blank" ><i class="icofont-external-link"></i></a>
                   </div>
                 </div>
               </div>
             </div>
             <div class="col-lg-4 col-md-6 portfolio-item filter-web">
               <div class="portfolio-wrap">
                 <img src="/assets/img/portfolio/portfolio-22.gif" class="img-fluid" alt="">
                 <div class="portfolio-info">
                   <h4>{{ 'portfolio.item2' | translate }}</h4>
                   <p>
                     {{ 'portfolio.item8' | translate }}
                     </p>
                   <div class="portfolio-links">
                    <!-- <a routerLink="/portfolio/4" title="More Details"><i class="icofont-eye"></i></a>-->
                    <a href="http://fastdiscarry.be/" title="More Details" target="_blank" ><i class="icofont-external-link"></i></a>

                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 portfolio-item filter-card">
              <div class="portfolio-wrap">
                <img src="/assets/img/portfolio/portfolio-55.gif" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>{{ 'portfolio.item2' | translate }}</h4>
                  <p>
                    {{ 'portfolio.item9' | translate }}
                    </p>
                  <div class="portfolio-links">
                   <!-- <a routerLink="/portfolio/5" title="More Details"><i class="icofont-eye"></i></a>-->
                    <a href="https://roadtojobs.eu/" title="More Details" target="_blank" ><i class="icofont-external-link"></i></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 portfolio-item filter-app">
              <div class="portfolio-wrap">
                <img src="/assets/img/portfolio/PORTFOLIO-11 .png" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>
                    {{ 'portfolio.item10' | translate }}
                    </h4>
                  <p>
                    {{ 'portfolio.item11' | translate }}
                    </p>
                  <div class="portfolio-links">
                    <div class="portfolio-links">
                      <!--<a routerLink="/portfolio/6" title="More Details"><i class="icofont-eye"></i></a>-->
                      <a href="https://socecba.com/" title="More Details" target="_blank" ><i class="icofont-external-link"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           <!-- <div class="col-lg-4 col-md-6 portfolio-item filter-card">
              <div class="portfolio-wrap">
                <img src="/assets/img/portfolio/portfolio-7.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Card 1</h4>
                  <p>Card</p>
                  <div class="portfolio-links">
                    <a href="/assets/img/portfolio/portfolio-7.jpg" data-gall="portfolioGallery" class="venobox" title="Card 1"><i class="icofont-eye"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i class="icofont-external-link"></i></a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-card">
              <div class="portfolio-wrap">
                <img src="/assets/img/portfolio/portfolio-8.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Card 3</h4>
                  <p>Card</p>
                  <div class="portfolio-links">
                    <a href="/assets/img/portfolio/portfolio-8.jpg" data-gall="portfolioGallery" class="venobox" title="Card 3"><i class="icofont-eye"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i class="icofont-external-link"></i></a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-wrap">
                <img src="/assets/img/portfolio/portfolio-9.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Web 3</h4>
                  <p>Web</p>
                  <div class="portfolio-links">
                    <a href="/assets/img/portfolio/portfolio-9.jpg" data-gall="portfolioGallery" class="venobox" title="Web 3"><i class="icofont-eye"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i class="icofont-external-link"></i></a>
                  </div>
                </div>
              </div>
            </div>-->
          </div>
        </div>
      </section><!-- End Our Portfolio Section -->
    </div>
  </section>

</main><!-- End #main -->

<!-- ======= Footer ======= -->
<app-footer></app-footer>

