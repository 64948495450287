<!-- ======= Header ======= -->
<header id="header" class="fixed-top">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-xl-10 d-flex align-items-center ">

        <span class="logo mr-auto text-dark"><a routerLink="/">Datnèk</a><span class="text-primary h1">.</span></span>

        <!-- Uncomment below if you prefer to use an image logo -->
        <!-- <a href="index.html" class="logo mr-auto"><img src="assets/img/logo.png" alt=""></a>-->

        <nav class="nav-menu d-none d-lg-block" *ngIf="active">
          <ul>
            <li [ngClass]="{'active':active === pages.home}"><a routerLink="/">{{ 'header.home' | translate }}</a></li>
            <li [ngClass]="{'active':active === pages.about}"><a routerLink="/about">{{ 'header.about' | translate }}</a></li>
            <li [ngClass]="{'active':active === pages.service}"><a routerLink="/service">{{ 'header.service' | translate }}</a></li>
            <li [ngClass]="{'active':active === pages.portfolio}"><a routerLink="/portfolio">{{ 'header.portfolio' | translate }}</a></li>
            <li [ngClass]="{'active':active === pages.team}"><a routerLink="/team">{{ 'header.team' | translate }}</a></li>
            <li [ngClass]="{'active':active === pages.blog}"><a routerLink="/blog">{{ 'header.blog' | translate }}</a></li>
            <li [ngClass]="{'active':active === pages.contact}"><a routerLink="/contact">{{ 'header.contact' | translate }}</a></li>
          </ul>
        </nav><!-- .nav-menu -->

        <div class="dropdown show">
          <a class="btn btn-link text-dark dropdown-toggle" href="#" role="button"
             id="dropdownmenuLang" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="flag-icon {{icon}}"></i>
          </a>
          <div class="dropdown-menu" aria-labelledby="dropdownmenuLang">
            <a class="dropdown-item" href="" (click)="changeLanguage('nl', $event)">
              <i class="flag-icon flag-icon-be mr-2"></i> {{ 'header.nl' | translate }}
            </a>
            <a class="dropdown-item" href=""  (click)="changeLanguage('fr',$event)">
              <i class="flag-icon flag-icon-fr mr-2"></i>  {{ 'header.fr' | translate }}
            </a>
            <a class="dropdown-item" href="" (click)="changeLanguage('en',$event)">
              <i class="flag-icon flag-icon-gb-eng mr-2"></i> {{ 'header.en' | translate }}
            </a>
          </div>
        </div>

        <button
           [matMenuTriggerFor]="menuMobileDatnek"
           class="btn btn-link text-dark nav-btn-mobile">
          <i class="icofont-navigation-menu"></i>
        </button>

        <mat-menu #menuMobileDatnek="matMenu">
          <button mat-menu-item
                  routerLink="/"
                  [ngClass]="{'active':active === pages.home}">{{ 'header.home' | translate }}</button>
          <button mat-menu-item
                  routerLink="/about"
                  [ngClass]="{'active':active === pages.about}">{{ 'header.about' | translate }}</button>
          <button mat-menu-item
                  routerLink="/service"
                  [ngClass]="{'active':active === pages.service}">{{ 'header.service' | translate }}</button>
          <button mat-menu-item
                  routerLink="/portfolio"
                  [ngClass]="{'active':active === pages.portfolio}">{{ 'header.portfolio' | translate }}</button>
          <button mat-menu-item
                  routerLink="/team"
                  [ngClass]="{'active':active === pages.team}">{{ 'header.team' | translate }}</button>
          <button mat-menu-item
                  routerLink="/blog"
                  [ngClass]="{'active':active === pages.blog}">{{ 'header.blog' | translate }}</button>
          <button mat-menu-item
                  routerLink="/contact"
                  [ngClass]="{'active':active === pages.contact}">{{ 'header.contact' | translate }}</button>
        </mat-menu>


        <!--<a href="#about" class="get-started-btn scrollto">Get Started</a>-->

      </div>
    </div>

  </div>
</header><!-- End Header -->
