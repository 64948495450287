<div class="row" data-aos="fade-up" data-aos-delay="100">
  <div class="col-lg-6">

    <div class="row">
      <div class="col-md-12">
        <div class="info-box">
          <i class="bx bx-map"></i>
          <h3>
            {{ 'contact.address' | translate }}
          </h3>
          <p>Bresstraat 21, bus 301 Antwerpen 2018</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="info-box mt-4">
          <i class="bx bx-envelope"></i>
          <h3>
            {{ 'contact.email' | translate }}
          </h3>
          <p>contact@datnek.be<br>contact@datnek.com</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="info-box mt-4">
          <i class="bx bx-phone-call"></i>
          <h3>
            {{ 'contact.phone' | translate }}
          </h3>
          <p>038282741 <br>+32 465 450 497</p>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-6">
    <form (ngSubmit)="sendEmail($event)" method="post" role="form" class="php-email-form">
      <div class="alert alert-danger" *ngIf="errorInput">
        {{ 'contact.errorInput' | translate }}
      </div>
      <div class="form-row">
        <div class="col form-group">
          <input type="text" name="name" class="form-control"
                 [(ngModel)]="mail.name"
                 id="name" placeholder="{{ 'contact.yourname' | translate }}" data-rule="minlen:4"
                 data-msg="Please enter at least 4 chars" required="" />
          <div class="validate"></div>
        </div>
        <div class="col form-group">
          <input type="email" class="form-control"
                 [(ngModel)]="mail.email"
                 name="email" id="email" placeholder="{{ 'contact.youremail' | translate }}"
                 data-rule="email" data-msg="Please enter a valid email" required="" />
          <div class="validate"></div>
        </div>
      </div>
      <div class="form-group">
        <input type="text" class="form-control"
               required=""
               [(ngModel)]="mail.subject"
               name="subject" id="subject" placeholder="{{ 'contact.subject' | translate }}"
               data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
        <div class="validate"></div>
      </div>
      <div class="form-group">
              <textarea class="form-control"
                        required=""
                        [(ngModel)]="mail.message"
                        name="message" rows="5" data-rule="required"
                        data-msg="Please write something for us" placeholder="{{ 'contact.message' | translate }}"></textarea>
        <div class="validate"></div>
      </div>
      <div class="mb-3">
        <div class="loading">{{ 'contact.loading' | translate }}</div>
        <div class="alert alert-danger" *ngIf="error">
          {{ 'contact.error' | translate }}
        </div>
        <div class="alert alert-success" *ngIf="success">{{ 'contact.response' | translate }}</div>
      </div>
      <div class="text-center"><button type="submit">{{ 'contact.btn' | translate }}</button></div>
    </form>
  </div>

</div>
