
<!-- ======= Header ======= -->
<app-header [active]="6"></app-header>

<main id="main">

  <!-- ======= Breadcrumbs ======= -->
  <section class="breadcrumbs">
    <div class="container">
      <h2>{{ 'header.contact' | translate }}</h2>
    </div>
  </section><!-- End Breadcrumbs -->

  <section class="inner-page">
    <div class="container" data-aos="fade-up">
      <!-- ======= Contact Section ======= -->
      <section id="contact" class="contact section-bg">
        <div class="container">
          <div class="section-title">
            <div class="row">
              <div class="col-12">
                <img src="../../../assets/img/RGPD.jpg" style="width: 250px; margin-top: -100px" alt="" class="mb-3">
              </div>
            </div>
            <h2 data-aos="fade-in">
              {{ 'header.contact' | translate }}
            </h2>
            <p data-aos="fade-in">
              {{ 'contact.content' | translate }}
            </p>
          </div>
          <app-contact-form></app-contact-form>
        </div>
      </section><!-- End Contact Section -->
    </div>
  </section>

</main><!-- End #main -->

<!-- ======= Footer ======= -->
<app-footer></app-footer>

