
<!-- ======= Header ======= -->
<app-header [active]="2"></app-header>

<main id="main">

  <!-- ======= Breadcrumbs ======= -->
  <section class="breadcrumbs">
    <div class="container">
      <h2>{{ 'header.about' | translate }}</h2>
    </div>
  </section><!-- End Breadcrumbs -->

  <section class="inner-page">
    <div class="container" data-aos="fade-up">
      <!-- ======= About Section ======= -->
      <section id="about" class="about">
        <div class="container" data-aos="fade-up">

          <div class="section-title">
            <h2>{{ 'header.about' | translate }}</h2>
            <p>{{ 'about.us' | translate }}</p>
          </div>

          <div class="row content">
            <div class="col-lg-6">
              <p>
                {{ 'about.content1' | translate }}
              </p>
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0">
              <p>
                {{ 'about.content2' | translate }}
              </p>

            </div>
          </div>

        </div>
      </section><!-- End About Section -->

      <!-- ======= About Section ======= -->
      <section id="" class="about section-bg">
        <div class="container">

          <div class="row">
            <div class="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-lg-start"></div>
            <div class="col-xl-7 pl-0 pl-lg-5 pr-lg-1 d-flex align-items-stretch">
              <div class="content d-flex flex-column justify-content-center">
                <h3 data-aos="fade-in" data-aos-delay="100">{{ 'about.item.item1.title' | translate }}</h3>
                <p data-aos="fade-in">
                  {{ 'about.item.item1.content' | translate }}
                </p>
                <div class="row">
                  <div class="col-md-6 icon-box" data-aos="fade-up">
                    <i class="icofont-speech-comments"></i>
                    <h4><a href="#">
                      {{ 'about.item.item5.title' | translate }}
                      </a></h4>
                    <p>
                      {{ 'about.item.item5.content' | translate }}
                     </p>
                  </div>
                  <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
                    <i class="icofont-users-social"></i>
                    <h4><a href="#">
                      {{ 'about.item.item2.title' | translate }}
                      </a></h4>
                    <p>
                      {{ 'about.item.item2.content' | translate }}
                    </p>
                  </div>
                  <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
                    <i class="icofont-ui-love"></i>
                    <h4><a href="#">
                      {{ 'about.item.item3.title' | translate }}
                      </a></h4>
                    <p>
                      {{ 'about.item.item3.content' | translate }}
                    </p>
                  </div>
                  <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
                    <i class="icofont-badge"></i>
                    <h4><a href="#">
                      {{ 'about.item.item4.title' | translate }}
                      </a></h4>
                    <p>
                      {{ 'about.item.item4.content' | translate }}
                    </p>
                  </div>
                </div>
              </div><!-- End .content-->
            </div>
          </div>

        </div>
      </section><!-- End About Section -->

    </div>
  </section>

</main><!-- End #main -->

<!-- ======= Footer ======= -->
<app-footer></app-footer>

