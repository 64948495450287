<!-- ======= Footer ======= -->
<footer id="footer">

  <div class="footer-top">
    <div class="container">
      <div class="row">

        <div class="col-lg-4 col-md-6 footer-contact">
          <h3>Datnèk<span>.</span></h3>
          <p>
            Bresstraat 21, <br>
            bus 301 <br>
            Antwerpen 2018 <br><br>
            <strong>{{ 'contact.phone' | translate }}:</strong> 038 272 841<br>
            <strong>{{ 'contact.email' | translate }}:</strong> contact@datnek.be<br>
          </p>
        </div>

        <div class="col-lg-4 col-md-6 footer-links">
          <h4>
            {{ 'footer.link' | translate }}
          </h4>
          <ul>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/">
              {{ 'header.home' | translate }}
            </a></li>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/about">
              {{ 'header.about' | translate }}
            </a></li>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/service">
              {{ 'header.service' | translate }}
            </a></li>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/portfolio">
              {{ 'header.portfolio' | translate }}
            </a></li>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/team">
              {{ 'header.team' | translate }}
            </a></li>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/blog">
              {{ 'header.blog' | translate }}
            </a></li>
            <li><i class="bx bx-chevron-right"></i> <a routerLink="/contact">
              {{ 'header.contact' | translate }}
            </a></li>
          </ul>
        </div>

        <div class="col-lg-4 col-md-6 footer-links">
          <h4>
            {{ 'footer.service' | translate }}
          </h4>
          <ul>
            <li><i class="bx bx-chevron-right"></i> <a href="#">
              {{ 'footer.item1' | translate }}
              </a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="#">
              {{ 'footer.item2' | translate }}
              </a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="#">
              {{ 'footer.item3' | translate }}
              </a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="#">
              {{ 'footer.item4' | translate }}
              </a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="#">
              {{ 'footer.item5' | translate }}
              </a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="container d-md-flex py-4">

    <div class="mr-md-auto text-center text-md-left">
      <div class="copyright">
        &copy; Copyright <strong><span>Datnek</span></strong>.  {{ 'footer.all' | translate }}
      </div>
      <div class="credits">
        Designed by <a href="https://datnek.com/">Datnek</a>
      </div>
    </div>
    <div class="social-links text-center text-md-right pt-3 pt-md-0">
      <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
      <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
      <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
      <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
      <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
    </div>
  </div>
</footer><!-- End Footer -->
