
<!-- ======= Team Section ======= -->
<section id="team" class="team" [ngClass]="{'section-bg':havebg}">
  <!-- ======= Our Team Section ======= -->
  <div class="container">

    <div class="section-title">
      <h2>
        {{ 'team.us' | translate }}
      </h2>
      <!--p>
        {{ 'team.content' | translate }}
      </p-->
    </div>

    <div class="row d-flex justify-content-center">
      <div class="col-lg-3 col-md-6 d-flex align-items-stretch">
        <div class="member" data-aos="fade-up" data-aos-delay="100">
          <div class="member-img">
            <img src="/assets/img/team/team-2.png" class="img-fluid" alt="">
            <div class="social">
              <a target="_blank"  href="https://twitter.com/strateg51948247"><i class="icofont-twitter"></i></a>
              <a target="_blank"  href="https://www.linkedin.com/in/danick-takam/"><i class="icofont-linkedin"></i></a>
            </div>
          </div>
          <div class="member-info">
            <h4>Danick TAKAM</h4>
            <span>Consultant .Net & Javascript</span>
            <span class="" style="font-size: 0.6em">
                    {{ 'home.dev.item1.title' | translate }}
                  </span>
            <!--a target="_blank"  href="https://danick-takam.datnek.be/" class="font-weight-bold" style="font-size: 0.6em">{{ 'team.cv' | translate }}</a-->
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 d-flex align-items-stretch">
        <div class="member" data-aos="fade-up" data-aos-delay="200">
          <div class="member-img">
            <img src="/assets/img/team/team-1.png" class="img-fluid" alt="">
            <div class="social">
              <a target="_blank"  href="https://twitter.com/ghislainyankam"><i class="icofont-twitter"></i></a>
              <a target="_blank"  href="https://www.linkedin.com/in/yankam-kezebou/"><i class="icofont-linkedin"></i></a>
            </div>
          </div>
          <div class="member-info">
            <h4>Arno YANKAM</h4>
            <span>Analyste fonctionnel & .Net développeur </span>
            <!--span  style="font-size: 0.6em">{{ 'home.dev.item1.title' | translate }}</span-->
            <!--a target="_blank" href="https://arno-yankam.datnek.be/" class="font-weight-bold" style="font-size: 0.6em">{{ 'team.cv' | translate }}</a-->
          </div>
        </div>
      </div>
    </div>

  </div><!-- End Our Team Section -->
</section><!-- End Team Section -->
