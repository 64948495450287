import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  language: string;
  icon: string;
  langName = 'datnek-08092020-lang';
  @Input() active: number;
  @Output() languageChangeEmitter: EventEmitter<string> = new EventEmitter<string>() ;
  pages =  {home: 1, about: 2, service: 3, portfolio: 4, team: 5, contact: 6, blog: 7};
  constructor(private translate: TranslateService) {
    this.language = localStorage.getItem(this.langName) ? localStorage.getItem(this.langName) : 'fr';
    translate.setDefaultLang(this.language);
  }

  ngOnInit(): void {
    this.changeLanguage(this.language, null);
  }

  changeLanguage(lang, e: MouseEvent): void{
    if (e){
      e.preventDefault();
    }
    this.language = lang;
    localStorage.setItem(this.langName, this.language);
    this.setIcon();
    this.translate.use(this.language);
    this.languageChangeEmitter.emit(this.language);
  }

  setIcon(): void{
    this.icon =  'flag-icon-' + (this.language === 'en' ? 'gb-eng' :
      this.language === 'nl' ? 'be' :
      this.language);
  }

}
