
<!-- ======= Header ======= -->
<app-header [active]="3"></app-header>

<main id="main">

  <!-- ======= Breadcrumbs ======= -->
  <section class="breadcrumbs" >
    <div class="container">

      <h2>{{ 'header.service' | translate }}</h2>

    </div>
  </section><!-- End Breadcrumbs -->

  <section class="inner-page">
    <div class="container" data-aos="fade-up">
      <!-- ======= Services Section ======= -->
      <section id="services" class="services section-bg">
        <div class="container">

          <div class="section-title">
            <h2 data-aos="fade-in">{{ 'header.service' | translate }}</h2>
            <p data-aos="fade-in " class="text-justify">
              {{ 'service.content1' | translate }}
              <br><br>

              {{ 'service.content2' | translate }}
            </p>
          </div>

          <div class="row">
            <div class="col-md-6 d-flex align-items-stretch" data-aos="fade-right">
              <div class="card">
                <div class="card-img">
                  <img src="/assets/img/tabs-1.jpg" alt="...">
                </div>
                <div class="card-body">
                  <h5 class="card-title"><a href="">
                    {{ 'service.title1' | translate }}
                  </a></h5>
                  <p class="card-text">
                    {{ 'service.item1' | translate }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 d-flex align-items-stretch" data-aos="fade-left">
              <div class="card">
                <div class="card-img">
                  <img src="/assets/img/tabs-2.jpg" alt="...">
                </div>
                <div class="card-body">
                  <h5 class="card-title"><a href="">
                    {{ 'service.title2' | translate }}
                  </a></h5>
                  <p class="card-text">
                    {{ 'service.item2' | translate }}
                  </p>
                </div>
              </div>

            </div>
            <div class="col-md-6 d-flex align-items-stretch" data-aos="fade-right">
              <div class="card">
                <div class="card-img">
                  <img src="/assets/img/tabs-3.jpg" alt="...">
                </div>
                <div class="card-body">
                  <h5 class="card-title"><a href="">
                    {{ 'service.title3' | translate }}
                    </a></h5>
                  <p class="card-text">
                    {{ 'service.item3' | translate }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 d-flex align-items-stretch" data-aos="fade-left">
              <div class="card">
                <div class="card-img">
                  <img src="/assets/img/tabs-4.jpg" alt="...">
                </div>
                <div class="card-body">
                  <h5 class="card-title"><a href="">
                    {{ 'service.title4' | translate }}
                    </a></h5>
                  <p class="card-text">
                    {{ 'service.item4' | translate }}
                  </p>
                  <!--<div class="read-more"><a href="#"><i class="icofont-arrow-right"></i> </a></div>-->
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>


    </div>
  </section>

</main><!-- End #main -->

<!-- ======= Footer ======= -->
<app-footer></app-footer>

