import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  slides: {title: string, content: string, img: string}[];
  constructor(private translateService: TranslateService) { }

 async ngOnInit(): Promise<void> {
   await this.refrechSlide();
  }

 async refrechSlide(): Promise<void> {
    this.slides = [
      {
        title: await this.translateService.get('slide.item1.title').toPromise(),
        content: await this.translateService.get('slide.item1.content').toPromise(),
        img: '/assets/img/slide/slide-1.jpg'
      },
      {
        title: await this.translateService.get('slide.item2.title').toPromise(),
        content: await this.translateService.get('slide.item2.content').toPromise(),
        img: '/assets/img/slide/slide-2.jpg'
      },
      {
        title: await this.translateService.get('slide.item3.title').toPromise(),
        content: await this.translateService.get('slide.item3.content').toPromise(),
        img: '/assets/img/slide/slide-3.jpg'
      },
      {
        title: await this.translateService.get('slide.item4.title').toPromise(),
        content: await this.translateService.get('slide.item4.content').toPromise(),
        img: '/assets/img/slide/slide-3.jpg'
      }
    ];
  }

  async onLanguageListener(language: string): Promise<void>{
    await this.refrechSlide();
  }

}
