import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
// import { SMTPClient } from 'emailjs';
// import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

  loading: boolean;
  error: boolean;
  success: boolean;
  mail: {name: string, subject: string, message: string, email: string};
  errorInput: boolean;
  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.initMail();
  }

  initMail(): void{
    this.mail = {name: null, subject: null, message: null, email: null};
  }

  // tslint:disable-next-line:typedef
  sendMailPost(mail: {subject: string, message: string, recipients: string[], html: string}){
    return this.http.post<{result: string}>(environment.mailing.MAIL_URL, mail);
  }

 async sendEmail(e): Promise<void> {
    e.preventDefault();
    this.loading = true;
    try {

      if (this.mail.message && this.mail.subject && this.mail.email && this.mail.name) {
        this.errorInput = false;
        this.error = false;
        const body = {
          message: this.mail.message,
          html: `
                  <p>Name ${this.mail.name}</p>
                  <p>Email ${this.mail.email}</p>
                  <p> ${this.mail.message} </p>`,
          recipients: ['contact@datenk.be'],
          subject: this.mail.subject,
        };

        const result = await this.sendMailPost(body).toPromise();
        this.success = true;
        console.log('success :', result);
        this.initMail();
        /*
        const client = new SMTPClient({
          user: environment.mailing.MAIL_USERNAME,
          password: environment.mailing.MAIL_PASSWORD,
          host: environment.mailing.MAIL_SERVER,
          ssl: environment.mailing.MAIL_USE_SSL,
          port: environment.mailing.MAIL_PORT // ,
         // tls: environment.mailing.MAIL_USE_TLS
        });

        const message = {
          text: this.mail.message,
          from: environment.mailing.MAIL_USERNAME,
          to: this.mail.email,
          // cc: 'else <else@your-email.com>',
          subject: this.mail.subject,
           attachment: [
             { data: '<html>i <strong>Name : </strong>' + this.mail.name + '</html>', alternative: true }
             // ,
            // { path: 'path/to/file.zip', type: 'application/zip', name: 'renamed.zip' },
           ]
        };

        // send the message and get a callback with an error or details of the message that was sent
        // @ts-ignore
        client.send(message,  (err, res) => {
          if (err){
            console.log('error: ', err);
            this.error = true;
          } else if (res){
            console.log('success: ', err);
            this.success = true;
          }
        });

        */
      } else {
        this.errorInput = true;
      }
    } catch (e) {
      console.log(e);
      this.error = true;
    } finally {
      this.loading = false;
    }


  }

}
