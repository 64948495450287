<!-- ======= Contact Section ======= -->
<section id="contact" class="contact">
  <div class="container" data-aos="fade-up">
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-center">
          <img src="../../assets/img/" alt="" class="">
        </div>
      </div>
    </div>
    <div class="section-title">
      <h2>{{ 'header.contact' | translate }}</h2>
    </div>
      <app-contact-form></app-contact-form>
  </div>
</section><!-- End Contact Section -->

