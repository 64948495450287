

<app-header [active]="1" (languageChangeEmitter)="onLanguageListener($event)"></app-header>

<!-- ======= Hero Section ======= -->
<section id="hero">
  <div class="hero-container">
    <div id="heroCarousel" class="carousel slide carousel-fade" data-ride="carousel">

      <ol class="carousel-indicators" id="hero-carousel-indicators"></ol>

      <div class="carousel-inner" role="listbox">

        <div class="carousel-item "
             *ngFor="let slide of slides; let i = index;"
             [ngClass]="{'active': i===0}"
             [ngStyle]="{'background-image': 'url(' + slide.img + ')'}">
          <div class="carousel-container">
            <div class="carousel-content container">
              <h2 class="animate__animated animate__fadeInDown" style="line-height:1!important;">
                {{slide.title}}
              </h2>
              <p class="animate__animated animate__fadeInUp" style="font-size: 1.5em!important; line-height: 1.2!important;">
                {{slide.content}}
              </p>
            </div>
          </div>
        </div>
      </div>

      <!--a class="carousel-control-prev" href="#heroCarousel" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon icofont-rounded-left" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#heroCarousel" role="button" data-slide="next">
        <span class="carousel-control-next-icon icofont-rounded-right" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a-->

    </div>
  </div>
</section><!-- End Hero -->
<main id="main">
  <!-- ======= Clients Section ======= -->
  <section id="clients" class="clients">
    <div class="container-fluid" data-aos="zoom-in">
      <div class="container">
        <div class="row d-flex justify-content-center">
          <div class="col-sm text-center text-wrap">
            <i class="icofont-light-bulb " style="font-size: 4em"></i>
            <p class="h5 text-dark font-weight-bold">
              {{ 'home.competence.item1.title' | translate }}
            </p>
            <p class="">
              {{ 'home.competence.item1.content' | translate }}
            </p>
          </div>
          <div class="col-sm text-center text-wrap">
            <i class="icofont-unique-idea" style="font-size: 4em"></i>
            <p class="h5 text-dark font-weight-bold">
              {{ 'home.competence.item2.title' | translate }}
            </p>
            <p class="">
              {{ 'home.competence.item2.content' | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section><!-- End Clients Section -->

  <!-- ======= About Section ======= -->
  <section id="about" class="about section-bg">
    <div class="container" data-aos="fade-up">

      <div class="row no-gutters">
        <div class="content col-xl-5 d-flex align-items-stretch">
          <div class="content">
            <h3>
              {{ 'home.about.title' | translate }}
            </h3>
            <p>
              {{ 'home.about.content' | translate }}
            </p>
            <a routerLink="/about" class="about-btn"><span>
              {{ 'home.about.about' | translate }}
            </span> <i class="bx bx-chevron-right"></i></a>
          </div>
        </div>
        <div class="col-xl-7 d-flex align-items-stretch">
          <div class="icon-boxes d-flex flex-column justify-content-center">
            <div class="row">
              <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
                <i class="icofont-data"></i>
                <h4>
                  {{ 'home.about.backend' | translate }}
                </h4>
                <span class="badge badge-secondary">C#</span>
                <span class="badge badge-secondary mx-1">Vb.net</span>
                <span class="badge badge-secondary mx-1">Asp.net core & MVC</span>
                <span class="badge badge-secondary mx-1">UWP</span>
                <span class="badge badge-secondary mx-1">Windows form</span>
                <span class="badge badge-secondary">Python</span>
                <span class="badge badge-secondary">Flask</span>
                <span class="badge badge-secondary mx-1">Node js</span>
                <span class="badge badge-secondary mx-1">REST API</span>
                <span class="badge badge-secondary mx-1">WCF</span>
                <span class="badge badge-secondary mx-1">PHP</span>
                <span class="badge badge-secondary mx-1">Symfony</span>
                <span class="badge badge-secondary mx-1">Laravel</span>
              </div>
              <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
                <i class=" icofont-interface"></i>
                <h4>
                  {{ 'home.about.frontend' | translate }}
                </h4>
                <span class="badge badge-secondary">HTML5</span>
                <span class="badge badge-secondary mx-1">CSS3</span>
                <span class="badge badge-secondary mx-1">Bootstrap </span>
                <span class="badge badge-secondary mx-1">Material design </span>
                <span class="badge badge-secondary mx-1">Javascript EMac6</span>
                <span class="badge badge-secondary mx-1">Typescript</span>
                <span class="badge badge-secondary mx-1">Ajax</span>
                <span class="badge badge-secondary">Jquery</span>
                <span class="badge badge-secondary mx-1">Angular</span>
              </div>
              <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
                <i class="icofont-database"></i>
                <h4>
                  {{ 'home.about.database' | translate }}
                </h4>
                <span class="badge badge-secondary">Mysql</span>
                <span class="badge badge-secondary mx-1">Sql server</span>
                <span class="badge badge-secondary mx-1"> Oracle </span>
                <span class="badge badge-secondary mx-1">Mongo db</span>
                <span class="badge badge-secondary mx-1">PostgreSQL</span>
                <span class="badge badge-secondary mx-1">Sqllite</span>
              </div>
              <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="400">
                <i class="icofont-brain-alt"></i>
                <h4>
                  {{ 'home.about.method' | translate }}
                </h4>
                <span class="badge badge-secondary">Mysql</span>
                <span class="badge badge-secondary mx-1">Devops</span>
                <span class="badge badge-secondary mx-1">CICD</span>
                <span class="badge badge-secondary mx-1">Docker</span>
                <span class="badge badge-secondary mx-1"> Agile </span>
                <span class="badge badge-secondary mx-1">Scrum</span>
                <span class="badge badge-secondary mx-1">Extrem programming</span>
                <span class="badge badge-secondary mx-1">Kanban</span>
                <span class="badge badge-secondary mx-1">Lean</span>
                <span class="badge badge-secondary mx-1">Merise</span>
                <span class="badge badge-secondary mx-1">UML</span>
                <span class="badge badge-secondary mx-1">Test unitaire</span>
              </div>


              <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
                <i class="icofont-hard-disk"></i>
                <h4>
                  {{ 'home.about.analyse' | translate }}
                </h4>
                <span class="badge badge-secondary">Couche db</span>
                <span class="badge badge-secondary mx-1">Apache Kafka</span>
                <span class="badge badge-secondary mx-1">Hadoop DFS</span>
                <span class="badge badge-secondary mx-1">Apache Spark</span>
                <span class="badge badge-secondary mx-1">Elastic</span>
                <span class="badge badge-secondary mx-1"> Cloudera</span>
                <span class="badge badge-secondary mx-1">Cassandra</span>
              </div>
              <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="400">
                <i class="icofont-brainstorming"></i>
                <h4>
                  {{ 'home.about.ia' | translate }}
                </h4>
                <span class="badge badge-secondary">Keras Flatten</span>
                <span class="badge badge-secondary mx-1">Tansorflow</span>
                <span class="badge badge-secondary mx-1">CNN</span>
                <span class="badge badge-secondary mx-1">MLP</span>
                <span class="badge badge-secondary mx-1"> R </span>
                <span class="badge badge-secondary mx-1">Matplotlib</span>
                <span class="badge badge-secondary mx-1">NumPy</span>
                <span class="badge badge-secondary mx-1">Pandas</span>
                <span class="badge badge-secondary mx-1">Python</span>
              </div>
              <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="400">
                <i class="icofont-link-alt"></i>
                <h4>
                  {{ 'home.about.blockchain' | translate }}
                </h4>
                <span class="badge badge-secondary mx-1">Ethereum</span>
                <span class="badge badge-secondary">Metamask</span>
                <span class="badge badge-secondary mx-1">Ganache</span>
                <span class="badge badge-secondary mx-1">Trufle</span>
                <span class="badge badge-secondary mx-1"> Solidity </span>
                <span class="badge badge-secondary mx-1">Mocha</span>
                <span class="badge badge-secondary mx-1">Chai</span>
              </div>




            </div>
          </div><!-- End .content-->
        </div>
      </div>

    </div>
  </section><!-- End About Section -->


  <!-- ======= Tabs Section ======= -->
  <section id="tabs" class="tabs">
    <div class="container" data-aos="fade-up">

      <ul class="nav nav-tabs row d-flex">
        <li class="nav-item col-3">
          <a class="nav-link active show" data-toggle="tab" href="#tab-1">
            <i class="icofont-data"></i>
            <h4 class="d-none d-lg-block">{{ 'home.about.analyse' | translate }}</h4>
          </a>
        </li>
        <li class="nav-item col-3">
          <a class="nav-link" data-toggle="tab" href="#tab-2">
            <i class="icofont-unique-idea"></i>
            <h4 class="d-none d-lg-block">{{ 'home.about.ia' | translate }} </h4>
          </a>
        </li>
        <li class="nav-item col-3">
          <a class="nav-link" data-toggle="tab" href="#tab-3">
            <i class="icofont-gears"></i>
            <h4 class="d-none d-lg-block">
              {{ 'home.about.ml' | translate }}
            </h4>
          </a>
        </li>
        <li class="nav-item col-3">
          <a class="nav-link" data-toggle="tab" href="#tab-4">
            <i class="icofont-brainstorming"></i>
            <h4 class="d-none d-lg-block">
              {{ 'home.about.dp' | translate }}
            </h4>
          </a>
        </li>
      </ul>

      <div class="tab-content">
        <div class="tab-pane active show" id="tab-1">
          <div class="row">
            <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0" data-aos="fade-up" data-aos-delay="100">
              <h3>
                {{ 'home.about.tab.tab1.item1' | translate }}
              </h3>
              <p class="font-italic">
                {{ 'home.about.tab.tab1.item2' | translate }}
              </p>
              <p class="">
                {{ 'home.about.tab.tab1.item3' | translate }}
              </p>
              <ul>
                <li><i class="ri-check-double-line"></i>

                   <strong>
                     {{ 'home.about.tab.tab1.item4' | translate }}
                   </strong>,
                  {{ 'home.about.tab.tab1.item5' | translate }}
                </li>
                <li><i class="ri-check-double-line"></i>
                  <strong>
                    {{ 'home.about.tab.tab1.item6' | translate }}
                  </strong>
                  {{ 'home.about.tab.tab1.item7' | translate }}

                </li>
                <li><i class="ri-check-double-line"></i>

                  <strong>
                    {{ 'home.about.tab.tab1.item8' | translate }}
                  </strong>
                  {{ 'home.about.tab.tab1.item9' | translate }}
                </li>
              </ul>
              <p>
              </p>
            </div>
            <div class="col-lg-6 order-1 order-lg-2 text-center" data-aos="fade-up" data-aos-delay="200">
              <img src="/assets/img/tabs-1.jpg" alt="" class="img-fluid">
            </div>
          </div>
        </div>
        <div class="tab-pane" id="tab-2">
          <div class="row">
            <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
              <h3>
                {{ 'home.about.tab.tab2.item1' | translate }}
              </h3>
              <p>
                {{ 'home.about.tab.tab2.item2' | translate }}
              </p>
              <p class="font-italic">
              </p>
              <!--<ul>
                <li><i class="ri-check-double-line"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                <li><i class="ri-check-double-line"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
                <li><i class="ri-check-double-line"></i> Provident mollitia neque rerum asperiores dolores quos qui a. Ipsum neque dolor voluptate nisi sed.</li>
                <li><i class="ri-check-double-line"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.</li>
              </ul>-->
            </div>
            <div class="col-lg-6 order-1 order-lg-2 text-center">
              <img src="/assets/img/tabs-2.jpg" alt="" class="img-fluid">
            </div>
          </div>
        </div>
        <div class="tab-pane" id="tab-3">
          <div class="row">
            <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
              <h3>
                {{ 'home.about.tab.tab3.item1' | translate }}
              </h3>
              <p>
                {{ 'home.about.tab.tab3.item2' | translate }}
              </p>
              <ul>
                <li><i class="ri-check-double-line"></i>
                  <strong>
                    {{ 'home.about.tab.tab3.item3' | translate }}
                  </strong>
                  {{ 'home.about.tab.tab3.item4' | translate }}
                </li>
                <li><i class="ri-check-double-line"></i>
                  <strong>
                    {{ 'home.about.tab.tab3.item5' | translate }}
                  </strong>
                  {{ 'home.about.tab.tab3.item6' | translate }}
                </li>
                <li><i class="ri-check-double-line"></i>
                  <strong>
                    {{ 'home.about.tab.tab3.item7' | translate }}
                  </strong>
                  {{ 'home.about.tab.tab3.item8' | translate }}
                </li>
                <li><i class="ri-check-double-line"></i>
                  <strong>
                    {{ 'home.about.tab.tab3.item9' | translate }}
                  </strong>
                  {{ 'home.about.tab.tab3.item10' | translate }}
                </li>
              </ul>
              <p class="font-italic">

              </p>
            </div>
            <div class="col-lg-6 order-1 order-lg-2 text-center">
              <img src="/assets/img/tabs-2.jpg" alt="" class="img-fluid">
            </div>
          </div>
        </div>
        <div class="tab-pane" id="tab-4">
          <div class="row">
            <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
              <h3>
                {{ 'home.about.tab.tab4.item1' | translate }}
              </h3>
              <p>
                {{ 'home.about.tab.tab4.item2' | translate }}
              </p>
              <p class="font-italic">
                {{ 'home.about.tab.tab4.item3' | translate }}
              </p>
            </div>
            <div class="col-lg-6 order-1 order-lg-2 text-center">
              <img src="/assets/img/tabs-4.jpg" alt="" class="img-fluid">
            </div>
          </div>
        </div>
      </div>

    </div>
  </section><!-- End Tabs Section -->

  <!-- ======= Services Section ======= -->
  <section id="services" class="services section-bg ">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>
          {{ 'home.partner.title' | translate }}
        </h2>
        <p>
          {{ 'home.partner.content' | translate }}
        </p>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
            <div class="d-flex justify-content-start align-items-center">
              <img src="/assets/img/clients/logoblanccomplet.png" class="img-fluid " alt="">
              <h4><a href="https://bps-bpv.brussels/fr/home" target="_blank">
                {{ 'home.partner.item1.title' | translate }}
              </a>
              </h4>
            </div>
            <p>
              {{ 'home.partner.item1.content' | translate }}
            </p>
          </div>
        </div>
        <div class="col-md-6 mt-4 mt-md-0">
          <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
            <div class="d-flex justify-content-start align-items-center">
              <img src="/assets/img/clients/2AERO.png" class="img-fluid " alt="">
              <h4><a href="https://www.cogito-es.eu/en/" target="_blank">
                {{ 'home.partner.item2.title' | translate }}
              </a></h4>
            </div>
            <p>
              {{ 'home.partner.item2.content' | translate }}
            </p>
          </div>
        </div>
        <div class="col-md-6 mt-4 mt-md-0">
          <div class="icon-box" data-aos="fade-up" data-aos-delay="300">
            <div class="d-flex justify-content-start align-items-center">
              <img src="/assets/img/clients/logo2.gif" class="img-fluid " style="width: 100px" alt="">
              <h4><a href="https://www.eureka-its.com/en/" target="_blank">
                {{ 'home.partner.item3.title' | translate }}
              </a></h4>
            </div>
            <p>
              {{ 'home.partner.item3.content' | translate }}
            </p>
          </div>
        </div>
        <div class="col-md-6 mt-4 mt-md-0">
          <div class="icon-box" data-aos="fade-up" data-aos-delay="400">
            <div class="d-flex justify-content-start align-items-center">
              <img src="/assets/img/clients/sheef.png" class="img-fluid " alt="">
              <h4><a href="http://www.sheef.cm/" TARGET="_blank">
                {{ 'home.partner.item4.title' | translate }}
              </a></h4>
            </div>
            <p>
              {{ 'home.about.partner.item4.content' | translate }}
            </p>
          </div>
        </div>
        <!--<div class="col-md-6 mt-4 mt-md-0">
          <div class="icon-box" data-aos="fade-up" data-aos-delay="500">
            <i class="icofont-earth"></i>
            <h4><a href="#">Magni Dolore</a></h4>
            <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque</p>
          </div>
        </div>
        <div class="col-md-6 mt-4 mt-md-0">
          <div class="icon-box" data-aos="fade-up" data-aos-delay="600">
            <i class="icofont-tasks-alt"></i>
            <h4><a href="#">Eiusmod Tempor</a></h4>
            <p>Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi</p>
          </div>
        </div>-->
      </div>

    </div>
  </section><!-- End Services Section -->


  <!-- ======= Portfolio Section ======= -->
  <section id="portfolio" class="portfolio">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>
          {{ 'home.goal.title' | translate }}
        </h2>
        <p>
          {{ 'home.goal.content' | translate }}
        </p>
      </div>
    </div>
  </section><!-- End Portfolio Section -->



  <!-- ======= Team Section ======= -->
    <app-teams></app-teams>
  <!-- End Team Section -->


  <!-- ======= Contact Section ======= -->
  <section id="contact" class="contact">
    <div class="container" data-aos="fade-up">
      <div class="row" data-aos="fade-up" data-aos-delay="100">

        <app-contact></app-contact>
      </div>

    </div>
  </section><!-- End Contact Section -->


</main><!-- End #main -->

<app-footer></app-footer>

<a href="#" class="back-to-top"><i class="icofont-simple-up"></i></a>

