
<!-- ======= Header ======= -->
<app-header [active]="5"></app-header>

<main id="main">

  <!-- ======= Breadcrumbs ======= -->
  <section class="breadcrumbs">
    <div class="container">

      <h2>{{ 'header.team' | translate }}</h2>

    </div>
  </section><!-- End Breadcrumbs -->

  <section class="inner-page">
    <div class="container" data-aos="fade-up">
      <!-- ======= Our Team Section ======= -->
      <section id="team" class="team">
        <app-teams [havebg]="false"></app-teams>
      </section><!-- End Our Team Section -->
    </div>
  </section>

</main><!-- End #main -->

<!-- ======= Footer ======= -->
<app-footer></app-footer>



