import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {BlogComponent} from './blog/blog.component';
import {BlogItemComponent} from './blog/blog-item/blog-item.component';
import {AboutPageComponent} from './about/about-page/about-page.component';
import {ContactPageComponent} from './contact/contact-page/contact-page.component';
import {ServicePageComponent} from './service/service-page/service-page.component';
import {PortfolioPageComponent} from './portfolio/portfolio-page/portfolio-page.component';
import {PortfolioPageItemComponent} from './portfolio/portfolio-page/portfolio-page-item/portfolio-page-item.component';
import {TeamsPageComponent} from './teams/teams-page/teams-page.component';
const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'about', component: AboutPageComponent},
  {path: 'blog', component: BlogComponent},
  {path: 'blog/:id', component: BlogItemComponent},
  {path: 'contact', component: ContactPageComponent},
  {path: 'service', component: ServicePageComponent},
  {path: 'portfolio', component: PortfolioPageComponent},
  {path: 'portfolio/:id', component: PortfolioPageItemComponent},
  {path: 'team', component: TeamsPageComponent},
  {path: 'not-found', component: NotFoundComponent},
  {path: '**', redirectTo: 'not-found'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
